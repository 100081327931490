//
//
//
//
//
//
//

export default {
    name: 'tobacco-echarts',
    inject: ['hideLoading', 'showLoading'],
    computed: {
        list() {
            return this.$store.state['tobaccoEcharts'].list;
        },
        colorList() {
            return this.$store.state['tobaccoQualityEcharts'].colorList;
        },
    },
    data() {
        return {
            barOption: {
                title: [
                    {
                        text: '云烟87增量模型',
                        left: 'center',
                        top: '1%',
                        show: false,
                    },
                    {
                        text: '暂无数据',
                        subtext: '请在上方输入查询参数',
                        left: 'center',
                        top: 'middle',
                        textStyle: {
                            fontSize: 40,
                        },
                        show: true,
                    },
                ],
                legend: {
                    top: '5%',
                },
                toolbox: {
                    show: true,
                    feature: {
                        //可下载为图
                        saveAsImage: { show: true },
                    },
                },
                grid: {
                    top: '15%',
                    right: '10%',
                },
                tooltip: {
                    trigger: 'item',
                    formatter: params => {
                        return (
                            params.marker +
                            params.seriesName +
                            ':' +
                            params.value
                        );
                    },
                },
                xAxis: {
                    type: 'category',
                    show: false,
                    // name: '类型',
                    axisLabel: {
                        show: false,
                    },
                },
                yAxis: [
                    {
                        type: 'value',
                        name: 'Δ株高\nΔ最大叶长\nΔ最大叶宽',
                        show: false,
                        position: 'left',
                        // alignTicks: true,
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#87CB65',
                            },
                        },
                        splitLine: {
                            show: false,
                        },
                        axisLabel: {
                            formatter: '{value} cm',
                        },
                    },
                    {
                        type: 'value',
                        name: 'Δ有效叶片数',
                        show: false,
                        position: 'right',
                        alignTicks: true,
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#FF1666',
                            },
                        },
                        splitLine: {
                            show: false,
                        },
                        axisLabel: {
                            formatter: '{value} 片',
                        },
                    },
                ],
                series: [
                    {
                        name: 'Δ株高/cm',
                        type: 'bar',
                        yAxisIndex: 0,
                        data: [],
                        label: {
                            show: true,
                            position: 'top',
                            formatter: '{c} cm',
                        },
                    },
                    {
                        name: 'Δ最大叶长/cm',
                        type: 'bar',
                        yAxisIndex: 0,
                        data: [],
                        label: {
                            show: true,
                            position: 'top',
                            formatter: '{c} cm',
                        },
                    },
                    {
                        name: 'Δ最大叶宽/cm',
                        type: 'bar',
                        yAxisIndex: 0,
                        data: [],
                        label: {
                            show: true,
                            position: 'top',
                            formatter: '{c} cm',
                        },
                    },
                    {
                        name: 'Δ有效叶片数/片',
                        type: 'bar',
                        yAxisIndex: 1,
                        data: [],
                        label: {
                            show: true,
                            position: 'top',
                            formatter: '{c} 片',
                        },
                    },
                ],
            },
        };
    },
    methods: {
        getChart() {
            // 处理数据
            if (!this.list || JSON.stringify(this.list) === '{}') {
                this.barOption.series[0].data = [];
                this.barOption.series[1].data = [];
                this.barOption.series[2].data = [];
                this.barOption.series[3].data = [];
                this.barOption.title[0].show = false;
                this.barOption.title[1].show = true;
                this.barOption.xAxis.show = false;
                this.barOption.yAxis[0].show = false;
                this.barOption.yAxis[1].show = false;
                return;
            }
            this.barOption.series[0].data = [this.list['hei']];
            this.barOption.series[1].data = [this.list['len']];
            this.barOption.series[2].data = [this.list['wid']];
            this.barOption.series[3].data = [this.list['cou']];
            this.barOption.xAxis.show = true;
            this.barOption.yAxis[0].show = true;
            this.barOption.yAxis[1].show = true;
            this.barOption.title[0].show = true;
            this.barOption.title[1].show = false;
        },

        /**
         * 拼接柱状图的series
         * @param names
         * @returns {*[]}
         */
        getBarSeries(names) {
            let series = [];
            let size = this.colorList.length;
            if (names) {
                for (let i = 0; i < names.length; i++) {
                    series.push({
                        type: 'bar',
                        name: names[i],
                        itemStyle: {
                            normal: {
                                //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                                color: new this.$echarts.graphic.LinearGradient(
                                    0,
                                    0,
                                    0,
                                    0.8,
                                    [
                                        {
                                            offset: 0,
                                            color: this.colorList[i % size][0],
                                        },
                                        {
                                            offset: 1,
                                            color: this.colorList[i % size][1],
                                        },
                                    ]
                                ),
                            },
                        },
                    });
                }
            }
            return series;
        },
    },
    mounted() {
        this.hideLoading();
    },
};
