//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
    name: "plantHeight-leafLength-leafWidth",
    inject: ["hideLoading", "showLoading"],
    data() {
        return {
            option: {
                color:['#ff6600','#2db7f5','#808bc6'],
                legend: {
                    bottom: '0%',
                    icon: 'rect',//用矩形代替线条
                    itemWidth: 25, //矩形宽度
                    itemHeight: 3, //矩形高度
                },
                title: {
                    text: '株高/cm',
                    left: 'center',
                },
                tooltip: {
                    trigger: 'axis',//鼠标移动到折线图上显示数据
                    // showContent: false
                },
                dataset: {
                    source: [
                        ['product', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59', '60', '61', '62', '63', '64', '65', '66', '67', '68', '69', '70', '71', '72', '73', '74', '75', '76', '77', '78', '79', '80', '81', '82', '83', '84', '85', '86', '87', '88', '89', '90', '91', '92'],
                        ['株高/上控制线', 26.54, 27.44, 28.07, 28.45, 28.63, 28.64, 28.52, 28.3, 28.03, 27.74, 27.48, 27.26, 27.15, 27.16, 27.35, 27.74, 28.36, 29.17, 30.1, 31.09, 32.09, 33.04, 33.87, 34.55, 35.16, 35.81, 36.61, 37.66, 39.07, 40.94, 43.35, 46.21, 49.38, 52.74, 56.16, 59.5, 62.65, 65.52, 68.24, 71, 73.95, 77.3, 81.21, 85.86, 91.37, 97.6, 104.32, 111.33, 118.43, 125.39, 132.03, 138.3, 144.17, 149.6, 154.57, 159.04, 162.99, 166.37, 169.19, 171.5, 173.37, 174.87, 176.09, 177.1, 177.97, 178.77, 179.5, 180.19, 180.81, 181.4, 181.93, 182.42, 182.87, 183.29, 183.68, 184.03, 184.36, 184.66, 184.95, 185.22],
                        ['株高/均值', 16.4, 16.78, 17.04, 17.22, 17.31, 17.35, 17.36, 17.35, 17.34, 17.36, 17.42, 17.54, 17.75, 18.06, 18.49, 19.06, 19.78, 20.63, 21.55, 22.51, 23.45, 24.36, 25.16, 25.86, 26.5, 27.16, 27.93, 28.86, 30.05, 31.58, 33.48, 35.69, 38.12, 40.68, 43.26, 45.77, 48.12, 50.25, 52.19, 54.04, 55.87, 57.77, 59.81, 62.08, 64.65, 67.63, 71.12, 75.21, 80, 85.61, 92.05, 99.13, 106.58, 114.13, 121.52, 128.48, 134.73, 140.02, 144.16, 147.24, 149.45, 150.97, 151.99, 152.69, 153.24, 153.8, 154.39, 155, 155.61, 156.21, 156.79, 157.34, 157.85, 158.3, 158.68, 158.99, 159.2, 159.31, 159.3, 159.16],
                        ['株高/下控制线', 6.26, 6.12, 6.02, 5.98, 5.99, 6.07, 6.2, 6.39, 6.65, 6.97, 7.36, 7.82, 8.35, 8.95, 9.63, 10.38, 11.21, 12.09, 13, 13.92, 14.82, 15.67, 16.46, 17.18, 17.84, 18.52, 19.24, 20.07, 21.04, 22.21, 23.6, 25.17, 26.86, 28.61, 30.36, 32.04, 33.6, 34.97, 36.14, 37.09, 37.79, 38.24, 38.41, 38.29, 37.93, 37.66, 37.91, 39.08, 41.58, 45.82, 52.07, 59.95, 68.99, 78.66, 88.47, 97.91, 106.48, 113.68, 119.12, 122.98, 125.53, 127.07, 127.89, 128.27, 128.51, 128.84, 129.28, 129.81, 130.4, 131.02, 131.65, 132.26, 132.83, 133.31, 133.69, 133.95, 134.04, 133.95, 133.65, 133.1],
                    ]
                },
                xAxis: {
                    name: '移栽天数',
                    type: 'category'
                },
                yAxis: {
                    gridIndex: 0,
                    max: 200
                },
                grid: {
                    top: '10%',
                    left: '3%',
                    right:'4%'
                },
                series: [
                    {
                        type: 'line',
                        smooth: true,
                        symbol: 'none',
                        seriesLayoutBy: 'row',
                        emphasis: {focus: 'series'},
                    },
                    {
                        type: 'line',
                        smooth: true,
                        symbol: 'none',
                        seriesLayoutBy: 'row',
                        emphasis: {focus: 'series'}
                    },
                    {
                        type: 'line',
                        smooth: true,
                        symbol: 'none',
                        seriesLayoutBy: 'row',
                        emphasis: {focus: 'series'}
                    },
                ]
            },
            barOption: {
                color:['#ff6600','#2db7f5','#808bc6'],
                legend: {
                    bottom: '0%',
                    icon: 'rect',
                    itemWidth: 25, //矩形宽度
                    itemHeight: 3, //矩形高度
                },
                title: {
                    text: '最大叶长/cm',
                        left: 'center',
                },
                tooltip: {
                    trigger: 'axis',//鼠标移动到折线图上显示数据
                    // showContent: false
                },
                dataset: {
                    source: [
                        ['product', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59', '60', '61', '62', '63', '64', '65', '66', '67', '68', '69', '70', '71', '72', '73', '74', '75', '76', '77', '78', '79', '80', '81', '82', '83', '84', '85', '86', '87', '88', '89', '90', '91', '92'],
                        ['最大叶长/上控制线', 20.67,	19.9,	19.5,	19.44,	19.69,	20.22,	20.99,	21.97,	23.13,	24.44,	25.86,	27.37,	28.92,	30.5,	32.06,	33.58,	35.02,	36.39,	37.7,	38.94,	40.13,	41.26,	42.34,	43.39,	44.4,	45.38,	46.34,	47.29,	48.24,	49.19,	50.14,	51.1,	52.03,	52.93,	53.79,	54.59,	55.31,	55.95,	56.54,	57.09,	57.63,	58.19,	58.79,	59.47,	60.25,	61.1,	62.04,	63.03,	64.07,	65.15,	66.25,	67.34,	68.39,	69.38,	70.28,	71.04,	71.66,	72.08,	72.31,	72.38,	72.36,	72.29,	72.24,	72.28,	72.44,	72.79,	73.29,	73.93,	74.6,	75.44,	76.24,	77.04,	77.8,	78.48,	79.04,	79.47,	79.71,	79.74,	79.53,	79.03],
                        ['最大叶长/均值', 16.15,	16.37,	16.59,	16.8,	17.04,	17.3,	17.59,	17.95,	18.36,	18.85,	19.43,	20.11,	20.91,	21.83,	22.89,	24.1,	25.46,	26.93,	28.44,	29.93,	31.36,	32.65,	33.76,	34.64,	35.34,	35.92,	36.46,	37.03,	37.68,	38.5,	39.52,	40.71,	42,	43.32,	44.63,	45.85,	46.92,	47.8,	48.51,	49.07,	49.52,	49.9,	50.24,	50.56,	50.92,	51.33,	51.81,	52.4,	53.12,	53.99,	55.02,	56.18,	57.41,	58.66,	59.86,	60.98, 61.94,	62.7,	63.22,	63.53,	63.69,	63.74,	63.74,	63.73,	63.77,	63.9,	64.12,	64.4,	64.74,	65.12,	65.53,	65.96,	66.38,	66.8,	67.19,	67.54,	67.84,	68.07,	68.22,	68.28],
                        ['最大叶长/下控制线', 11.63,	12.85,	13.67,	14.17,	14.38,	14.37,	14.2,	13.92,	13.59,	13.27,	13,	12.86,	12.89,	13.16,	13.72,	14.62,	15.9,	17.46,	19.18,	20.93,	22.59,	24.05,	25.18,	25.89,	26.28,	26.46,	26.58,	26.76,	27.13,	27.81,	28.9,	30.32,	31.96,	33.72,	35.47,	37.12,	38.54,	39.65,	40.48,	41.05,	41.41,	41.61,	41.68,	41.66,	41.59,	41.55,	41.59,	41.77,	42.16,	42.82,	43.79,	45.02,	46.43,	47.93,	49.45,	50.91,	52.22,	53.32,	54.13,	54.68,	55.03,	55.2,	55.24,	55.19,	55.11,	55.02,	54.94,	54.87,	54.82,	54.8,	54.82,	54.87,	54.97,	55.13,	55.34,	55.62,	55.97,	56.4,	56.91,	57.52],
                    ]
                },
                xAxis: {
                    name: '移栽天数',
                        type: 'category'
                },
                yAxis: {
                    gridIndex: 0,
                    max: 90
                },
                grid: {
                    top: '10%',
                    left: '3%',
                    right: '4%'
                },
                series: [
                    {
                        type: 'line',
                        smooth: true,
                        symbol: 'none',
                        seriesLayoutBy: 'row',
                        emphasis: {focus: 'series'},
                    },
                    {
                        type: 'line',
                        smooth: true,
                        symbol: 'none',
                        seriesLayoutBy: 'row',
                        emphasis: {focus: 'series'}
                    },
                    {
                        type: 'line',
                        smooth: true,
                        symbol: 'none',
                        seriesLayoutBy: 'row',
                        emphasis: {focus: 'series'}
                    },
                ]
            },
            barOptions: {
                color:['#ff6600','#2db7f5','#808bc6'],
                legend: {
                    bottom: '0%',
                    icon: 'rect',
                    itemWidth: 25, //矩形宽度
                    itemHeight: 3, //矩形高度
                },
                title: {
                    text: '最大叶宽/cm',
                    left: 'center',
                },
                tooltip: {
                    trigger: 'axis',//鼠标移动到折线图上显示数据
                    // showContent: false
                },
                dataset: {
                    source: [
                        ['product', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59', '60', '61', '62', '63', '64', '65', '66', '67', '68', '69', '70', '71', '72', '73', '74', '75', '76', '77', '78', '79', '80', '81', '82', '83', '84', '85', '86', '87', '88', '89', '90', '91', '92'],
                        ['最大叶宽/上控制线', 8.68,	7.93,	7.54,	7.44,	7.62,	8.03,	8.63,	9.39,	10.26,	11.22,	12.22,	13.24,	14.22,	15.13,	15.94,	16.6,	17.11,	17.49,	17.82,	18.15,	18.56,	19.09,	19.81,	20.76,	21.88,	23.1,	24.34,	25.51,	26.55,	27.36,	27.9,	28.2,	28.31,	28.29,	28.19,	28.07,	27.97,	27.96,	28.02,	28.16,	28.36,	28.64,	28.97,	29.36,	29.8,	30.29,	30.85,	31.46,	32.14,	32.9,	33.72,	34.59,	35.47,	36.33,	37.15,	37.9,	38.53,	39.03,	39.36,	39.56,	39.65,	39.66,	39.62,	39.56,	39.51,	39.5,	39.51,	39.56,	39.63,	39.73,	39.84,	39.96,	40.1,	40.24,	40.38,	40.52,	40.65,	40.77,	40.87,	40.95],
                        ['最大叶宽/均值', 6.25,	6.3,	6.39,	6.53,	6.7,	6.91,	7.15,	7.43,	7.74,	8.09,	8.46,	8.87,	9.31,	9.77,	10.26,	10.78,	11.32,	11.88,	12.46,	13.04,	13.63,	14.21,	14.79,	15.35,	15.91,	16.46,	17,	17.54,	18.09,	18.64,	19.2,	19.76,	20.32,	20.87,	21.4,	21.91,	22.38,	22.83,	23.23,	23.59,	23.89,	24.13,	24.31,	24.41,	24.45,	24.45,	24.48,	24.58,	24.81,	25.21,	25.81,	26.58,	27.46,	28.41,	29.35,	30.25,	31.04,	31.68,	32.11,	32.37,	32.48,	32.49,	32.44,	32.36,	32.28,	32.24,	32.24,	32.28,	32.35,	32.45,	32.58,	32.74,	32.91,	33.11,	33.32,	33.54,	33.77,	34,	34.24,	34.48],
                        ['最大叶宽/下控制线', 3.82,	4.67,	5.25,	5.61,	5.78,	5.78,	5.67,	5.47,	5.22,	4.95,	4.7,	4.5,	4.4,	4.41,	4.59,	4.96,	5.54,	6.28,	7.1,	7.93,	8.7,	9.34,	9.77,	9.95,	9.94,	9.81,	9.66,	9.58,	9.63,	9.92,	10.49,	11.32,	12.32,	13.44,	14.6,	15.74,	16.8,	17.7,	18.45,	19.02,	19.41,	19.62,	19.64,	19.46,	19.59,	19.61,	19.92,	20.07,	20.12,	20.15,	20.23, 	20.38 ,	20.53,	20.98,	21.55,	22.6,	23.55,	24.32,	24.85,	25.17,	25.31,	25.33,	25.26,	25.15,	25.05,	24.98,	24.97,	25,	25.07,	25.18,	25.33,	25.51,	25.73,	25.98,	26.26,	26.56,	26.89,	27.24,	27.61,	28],
                    ]
                },
                xAxis: {
                    name: '移栽天数',
                    type: 'category'
                },
                yAxis: {
                    gridIndex: 0,
                    max: 45
                },
                grid: {
                    top: '10%',
                    left: '3%',
                    right: '4%'
                },
                // itemStyle: {
                //     normal: {
                //         areaStyle: {
                //             // 区域图，纵向渐变填充
                //             color : (
                //                     [
                //                         [0, 'rgba(255,0,0,0.8)'],[0.8, 'rgba(255,255,255,0.1)'],
                //                         [0, 'rgba(255,255,0,0.5)'],[10, 'rgba(255,255,255,0.9)'],
                //                     ]
                //             )
                //         }
                //     }
                // },
                series: [
                    {
                        type: 'line',
                        smooth: true,
                        symbol: 'none',
                        seriesLayoutBy: 'row',
                        emphasis: {focus: 'series'},
                    },
                    {
                        type: 'line',
                        smooth: true,
                        symbol: 'none',
                        seriesLayoutBy: 'row',
                        emphasis: {focus: 'series'}
                    },
                    {
                        type: 'line',
                        smooth: true,
                        symbol: 'none',
                        seriesLayoutBy: 'row',
                        emphasis: {focus: 'series'}
                    },
                ]
            },
        }
    },
    mounted() {
        this.hideLoading();
    }
}
