//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TobaccoEchartsHeader from "./tobacco-echarts-header";
import PlantHeightLeafLengthLeafWidth from "./plantHeight-leafLength-leafWidth";

export default {
    name: "index",
    inject: ["hideLoading", "showLoading"],
    components: {PlantHeightLeafLengthLeafWidth, TobaccoEchartsHeader},
    data() {
        return {
            active: 1
        }
    },
    methods: {
        changeCheck() {
            this.active = 1;
        },
        changeCheck1() {
            this.active = 2;
        }
    }
}
