//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TobaccoEcharts from "./tobacco-echarts";

export default {
    name: "tobacco-echarts-header",
    components: {TobaccoEcharts},
    inject: ["hideLoading", "showLoading"],
    data() {
        return {
            queryParams: {
                currentDay: "",
                dayOffset: "",
                temperature: "",
                humidity: "",
            },
            rules: {
                currentDay: [
                    {required: true, message: '输入移栽天数', trigger: 'blur'},
                    { type: 'number', message: '必须为数字值'}
                ],
                dayOffset: [
                    {required: true, message: '输入待预测的天数', trigger: 'blur'},
                    { type: 'number', message: '必须为数字值'},
                ],
                temperature: [
                    {required: true, message: '输入大气温度', trigger: 'blur'},
                ],
                humidity: [
                    {required: true, message: '输入大气湿度', trigger: 'blur'},
                ]
            }
        }
    },
    methods: {
        /**
         * 查询
         */
        search() {
            this.$refs["queryParams"].validate(async (valid) => {
                if (valid) {
                    this.showLoading();
                    await this.$store.dispatch('tobaccoEcharts/search', this.queryParams);
                    this.$refs.headers.getChart();
                    this.hideLoading();
                } else {
                    return false;
                }
            });
        },
    },
    mounted() {
        this.hideLoading();
    }
}
